import React, { useContext, useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ModalShell from "../ModalShell";
import DataContext from "../../../context/DataContext";
import ProviderSearchContext from "../../../context/ProviderSearchContext";
import HoursOfOperation from "./ProviderHours/HoursOfOperation";
import ProviderInformation from "./ProviderInformation";
import axios from "../../api/axios";
import ResponseModal from "../ResponseModal";
import LoadingIndicator from "../LoadingIndicator";
import ApiRequestErrorHandler from "../ApiRequestErrorHandler";
import ProviderServices from "./ProviderServices";
import ProviderToProvider from "./ProviderToProviderRelation";
import PropTypes from 'prop-types';

export default function ProviderDetailv2({ providerDetail, open, setOpen }) {
  const { userType, userRoles, loggedInUser, accessToken } =
    useContext(DataContext);
  const { setProvider, setActiveProviders } = useContext(ProviderSearchContext);
  const [activeProvObj, setActiveProvObj] = useState("");
  const [parentTrigger, setParentTrigger] = useState(false);
  const [providerInformation, setProviderInformation] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [responseModal, setResponseModal] = useState(false);
  const [responseBreakdown, setResponseBreakdown] = useState("");
  const [isError, setIsError] = useState(false);
  const [mountComponent, setMountComponent] = useState(false);

  const closeResModal = () => {
    setIsError(false);
    setResponseModal(false);
    setResponseBreakdown("");
    setParentTrigger(!parentTrigger);
    setMountComponent(false);
  };
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`/api/provider/${providerDetail.pk}?many-provider-types=true`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then((response) => {
        let data = response.data;
        setProviderInformation(data);
        setIsLoading(false);
        setMountComponent(true);
      })
      .catch((error) => {
        setIsLoading(false);
        let res = error.response.data;
        let errArr = ApiRequestErrorHandler(res);
        setIsError(true);
        setResponseModal(true);
        setResponseBreakdown(errArr);
      });
  }, [accessToken, setProviderInformation, parentTrigger, providerDetail.pk]);

  const navigate = useNavigate();

  const navToProCliSearch = (prov) => {
    setProvider([prov.pk]);
    setActiveProviders([prov.name]);
    navigate(`/provider-client-search`);
  };

  useEffect(() => {
    if (
      loggedInUser.entity &&
      loggedInUser.entity.entity_type_label === "Provider"
    ) {
      axios
        .get(`/api/provider/?managed_by=${loggedInUser.pk}&simplified=true?`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          let data = response.data.results;
          let obj = {};

          for (const item in data) {
            obj[data[item].pk] = data[item].pk;
          }

          setActiveProvObj(obj);
        });
    }
  }, [accessToken, loggedInUser]);

  return (
    <ModalShell
      open={open}
      setOpen={setOpen}
      title={providerInformation.name}
      fullScreen={true}
      color={"#9333EA"}
    >
      <LoadingIndicator isLoading={isLoading} />

      <div className="w-[90%]">
        <div className="flex-wrap rounded-b-lg p-0 justify-between">
          <div className="lg:w-full mx-4">
            <ProviderInformation
              providerDetail={providerInformation}
              activeProvObj={activeProvObj}
              parentTrigger={parentTrigger}
              setParentTrigger={setParentTrigger}
            />
            <div className="flex">
              {userRoles.permissions.includes(
                "provider.view_providerrelation"
              ) && mountComponent ? (
                <div className="w-[50%]">
                  <ProviderToProvider
                    providerId={providerInformation.pk}
                    activeProvObj={activeProvObj}
                  />
                </div>
              ) : (
                ""
              )}
              <div
                className={
                  userRoles.permissions.includes(
                    "provider.view_providerrelation"
                  )
                    ? "w-[50%]"
                    : "w-full"
                }
              >
                {userType === "Provider" ? (
                  ""
                ) : (
                  <div className="m-auto text-right mt-3 w-fit">
                    <Button
                      onClick={() => navToProCliSearch(providerInformation)}
                      variant="outlined"
                      className="border-purple-600 text-center font-bold text-purple-600"
                    >
                      View All Clients
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="lg:w-full">
            {userRoles.permissions.includes(
              "provider.view_providerofficehours"
            ) ? (
              <HoursOfOperation
                providerDetail={providerInformation}
                activeProvObj={activeProvObj}
              />
            ) : (
              ""
            )}
            {providerInformation.pk && (
              <ProviderServices
                providerId={providerInformation.pk}
                activeProvObj={activeProvObj}
                parentTrigger={parentTrigger}
                setParentTrigger={setParentTrigger}
              />
            )}
          </div>
        </div>
      </div>
      <ResponseModal
        title={`${isError ? "Failed" : "Successful"}`}
        isError={isError}
        description={isError ? "" : responseBreakdown}
        openBool={responseModal}
        setOpenBool={setResponseModal}
        errorMessage={responseBreakdown}
        handleCloseFunc={closeResModal}
      />
    </ModalShell>
  );
}

ProviderDetailv2.propTypes = {
  providerDetail: PropTypes.shape({
    pk: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // Ensure pk is a string or number
  }).isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired
};