import { useCallback } from "react";
import {
  setfetchError,
  setfetchIsLoading,
  setfetchSuccess,
  setFieldData,
  setResultCount,
  setShouldFetchData,
} from "../state/actions";
import { case_detail } from "../../../../../../../../../../api/endpoints/case";
import ApiRequestErrorHandler from "../../../../../../../../../../global/ApiRequestErrorHandler";
import { ACTION_TYPE } from "../../../../../CaseFollowUps/components/types/actionTypes";

export default function useGetAllActionTypes(
  caseId,
  search,
  paginationUrl,
  accessToken,
  dispatch
) {
  const getActionTypeKey = (actionType) => {
    if (actionType === ACTION_TYPE.PROVIDER_FOLLOW_UP) {
      return "on_provider";
    } else if (actionType === ACTION_TYPE.LAW_FIRM_FOLLOW_UP) {
      return "on_firm";
    } else {
      return "on_case";
    }
  };

  const fetchAllFollowUpData = useCallback(() => {
    let newData = [];
    setfetchIsLoading(dispatch, true);
    setShouldFetchData(dispatch, false);
    
    let actionTypes = [
      ACTION_TYPE.CLIENT_FOLLOW_UP,
      ACTION_TYPE.LAW_FIRM_FOLLOW_UP,
      ACTION_TYPE.PROVIDER_FOLLOW_UP,
    ].join(",");

    case_detail
      .getFollowUpData(caseId, search, paginationUrl, actionTypes, accessToken)
      .then((res) => {
        console.log(res);
        setResultCount(dispatch, {
          total: res.count,
          current: res.results.length,
        });
        res.results.forEach((item) => {
         let newObj = {
            id: item.id,
            fieldName: item.id,
            client:{
              id:item['on_case'].client.pk,
              name:item['on_case'].client.name
            },
            label:
              item.action_type === ACTION_TYPE.CLIENT_FOLLOW_UP
                ? item[getActionTypeKey(ACTION_TYPE.CLIENT_FOLLOW_UP)].client.name
                : item[getActionTypeKey(item.action_type)].name,
            actionType: item.action_type,
            action_detail: item.action_detail,
            relative_due_date: item.relative_due_date,
            due_date: item.due_date,
            clock_state: item.clock_state,
            status: item.status,
            createdBy: item.created_by,
            updatedAt: item.updated_at,
            updatedBy: item.updated_by,
            completedAt: item.completed_at,
            completedBy: item.completed_by,
            ...(item.action_type === ACTION_TYPE.PROVIDER_FOLLOW_UP ? 
            {provider: item.on_provider.name} : {}),
        
        };
        newData.push(newObj);
         
        });
        setFieldData(dispatch, {
          fieldName: null,
          data: newData
        });
        return res;
      })
      .then((res) => {
        setfetchSuccess(dispatch, true);
        setfetchIsLoading(dispatch, false);
        return res;
      })
      .catch((error) => {
        let errArr = ApiRequestErrorHandler(error.response);
        setfetchError(dispatch, errArr);
        setfetchIsLoading(dispatch, false);
      });
  }, [caseId, search, paginationUrl, accessToken, dispatch]);

  return [fetchAllFollowUpData];
}
