import React, { useContext, useState } from "react";
import { ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import TextsmsIcon from "@mui/icons-material/Textsms";
import DescriptionIcon from "@mui/icons-material/Description";
import DataContext from "../../context/DataContext";
import axios from "../api/axios";

function ContactControlsNoStyling({
  number,
  closeModal,
  buttonWidth,
  buttonHeight,
  smsTitle,
  smsIconColor,
  smsBackgroundColor,
  customIconSize,
  phoneTitle,
  phoneIconColor,
  phoneBackgroundColor,
  inPrimaryContact,
  caseId,
  clientId
}) {
  const [active, setActive] = useState(null);

  const { accessToken, userRoles } = useContext(DataContext);

  const isViewRequestDocumentButton = userRoles.permissions.includes("filemanager.add_document") && inPrimaryContact;

  const onRequestDocument = () => {
    if (caseId && clientId) {
      const dataSubmit = { case: caseId, client: clientId };
      axios.post(`/api/temporary_token/`, dataSubmit, {
        headers: { Authorization: `Token ${accessToken}` }
      });
    }
  };

  const dialClient = (number) => {
    const widgetToggle = document.querySelector(".Adapter_toggle");
    const widgetContainer = document.querySelector(".Adapter_root");
    if (widgetContainer.classList.contains("Adapter_minimized")) {
      widgetToggle.click();
    }
    if (closeModal) {
      closeModal();
    }

    document
      .querySelector("#rc-widget-adapter-frame")
      .contentWindow.postMessage(
        {
          type:
            active === "phone"
              ? "rc-adapter-new-call"
              : "#rc-widget-adapter-frame",
          phoneNumber: number,
          toDial: false,
          conversation: active === "phone" ? false : true, // will go to conversation page if conversation existed
        },
        "*"
      );
  };
  const handleChange = (e, newActive) => {
    setActive(newActive);
    if (newActive !== "documents") {
      dialClient(number); 
    }
  };

  const control = {
    value: active,
    onChange: handleChange,
    exclusive: true,
  };
  return (
    <ToggleButtonGroup className="mx-auto" size="small" {...control}>
      <Tooltip title={phoneTitle}>
        <ToggleButton
          value="phone"
          style={{
            borderColor: phoneIconColor,
            width: buttonWidth,
            height: !buttonHeight ? buttonWidth : buttonHeight,
            backgroundColor:
              active === "phone" ? phoneIconColor : phoneBackgroundColor,
            color: active === "phone" ? phoneBackgroundColor : phoneIconColor,
            borderRadius: "4px 0px 0px 4px"
          }}
        >
          <PhoneIcon
            fontSize="inherit"
            className="phone-icon"
            data-testid="phone-icon"
            style={{ fontSize: customIconSize ? customIconSize : "" }}
          />
        </ToggleButton>
      </Tooltip>
      <Tooltip title={smsTitle}>
        <ToggleButton
          value="sms"
          style={{
            borderColor: smsIconColor,
            width: buttonWidth,
            height: !buttonHeight ? buttonWidth : buttonHeight,
            backgroundColor:
              active === "sms" ? smsIconColor : smsBackgroundColor,
              color: active === "sms" ? smsBackgroundColor : smsIconColor,
          }}
        >
          <TextsmsIcon
            fontSize="inherit"
            className="sms-icon"
            data-testid="sms-icon"
            style={{ fontSize: customIconSize ? customIconSize : "" }}
          />
        </ToggleButton>
      </Tooltip>
      {isViewRequestDocumentButton && (
        <Tooltip title="Request Documents">
        <ToggleButton
          onClick={onRequestDocument}
          value="documents"
          style={{
            borderColor: "rgb(234, 179, 8)",
            width: buttonWidth,
            height: !buttonHeight ? buttonWidth : buttonHeight,
            backgroundColor: phoneBackgroundColor,
            color: "rgb(234, 179, 8)",
            borderRadius: "0 4px 4px 0"
          }}
        >
          <DescriptionIcon
            className="request-documents-icon"
            data-testid="request-documents-icon"
            fontSize="inherit"
            style={{ fontSize: customIconSize ? customIconSize : "" }}
          />
        </ToggleButton>
      </Tooltip>
      )}
    </ToggleButtonGroup>
  );
}

export default ContactControlsNoStyling;
