import React, { useContext, useEffect, useReducer } from "react";
import useGetAllActionTypes from "./hooks/useGetAllActionTypes";
import { useParams } from "react-router-dom";
import DataContext from "../../../../../../../../../../context/DataContext";
import {
  FollowUpListDispatchContext,
  FollowUpListStateContext,
} from "./context/HistoryListContext";
import {
  setCaseInfo,
  setCurrentPage,
  setCurrentPageURL,
  setOrderingVal,
  setShouldFetchData,
} from "./state/actions";
import LoadingIndicator from "../../../../../../../../../global/LoadingIndicator";
import { historyListReducer, initialState } from "./state/historyListReducer";
import { Button, List, ListItem, Stack } from "@mui/material";
import PaginationControls from "../../../../../../../../../global/PaginationControls";
import FollowUpListItem from "./components/FollowUpListItem";
import OrderingArrow from "../../../../../../../../../global/OrderingArrow";
import STRINGS from "../../../../CaseFollowUps/components/utils/strings";
import NoAvailableFollowUps from "../../../../CaseFollowUps/components/ui/NoAvailableFollowUps";
export default function HistoryListView({ caseInfo }) {
  const params = useParams();
  const [state, dispatch] = useReducer(historyListReducer, initialState);
  const { accessToken } = useContext(DataContext);
  const [fetchAllFollowUpData] = useGetAllActionTypes(
    params.id,
    state.orderingVal,
    "",
    accessToken,
    dispatch
  );

  useEffect(() => {
    let active = true;
    if (active) {
      if (!state.fetchIsLoading && state.shouldFetchData && state.orderingVal) {
        fetchAllFollowUpData(
          params.id,
          state.orderingVal,
          state.paginationUrl,
          accessToken,
          dispatch
        );
      }
    }
    return () => {
      active = false;
    };
  }, [
    state.shouldFetchData,
    params.id,
    state.orderingVal,
    state.paginationUrl,
    state.fetchIsLoading,
    accessToken,
    dispatch,
    fetchAllFollowUpData,
  ]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (params.id) {
        setShouldFetchData(dispatch, true);
      }
    }
    return () => {
      active = false;
    };
  }, [dispatch, params]);

  useEffect(() => {
    let active = true;
    if (active) {
      if (caseInfo) {
        setCaseInfo(dispatch, caseInfo);
      }
    }
    return () => {
      active = false;
    };
  }, [dispatch, caseInfo]);

  const handleChange = (event, value) => {
    setCurrentPage(dispatch, value);
    if (value === 1) {
      setCurrentPageURL(dispatch, `limit=${state.resLimit}`);
    }
    setCurrentPageURL(
      dispatch,
      `limit=${state.resLimit}&offset=${state.resLimit * (value - 1)}`
    );
    setShouldFetchData(dispatch, true);
  };

  const resetPagination = () => {
    setCurrentPage(dispatch, 1);
    setCurrentPageURL(dispatch, "");
    setShouldFetchData(dispatch, true);
  };

  const sortList = (value) => {
    setShouldFetchData(dispatch, true);
    setOrderingVal(dispatch, value);
    resetPagination();
  };

  return (
    <FollowUpListDispatchContext.Provider value={dispatch}>
      <FollowUpListStateContext.Provider value={state}>
        <h1 className="text-center text-[1.6rem] text-slate-800 mb-3">
          {STRINGS.FOLLOW_UP_LIST_TITLE}
        </h1>
        
        <div className="bg-slate-200">
          <div className="flex justify-between py-3 px-0 border-2 border-slate-300 w-full bg-white">
            <div className="w-[9%] text-center"></div>
            <div className="w-[20%] text-center">
              Due Date{" "}
              <OrderingArrow
                val={"due_date"}
                orderingVal={state.orderingVal}
                setOrderingVal={sortList}
              />
            </div>
            <div className="w-[20%] text-center">
              Type{" "}
              <OrderingArrow
                val={"action_type"}
                orderingVal={state.orderingVal}
                setOrderingVal={sortList}
              />
            </div>
            <div className="w-[20%] text-center">
              Completed At{" "}
              <OrderingArrow
                val={"completed_at"}
                orderingVal={state.orderingVal}
                setOrderingVal={sortList}
              />
            </div>
            <div className="w-[30%] text-center">
              Completed By{" "}
              <OrderingArrow
                val={"completed_by"}
                orderingVal={state.orderingVal}
                setOrderingVal={sortList}
              />
            </div>
          </div>
          <List className="p-0 items-start h-[25vh] overflow-y-auto">
            {!state.fetchIsLoading ? (
              state.data.length > 0 ? (
                state.data.map((item, idx) => (
                  <ListItem className="m-0 p-0"  key={"follow-up-item_list_" + item.id }>
                    <FollowUpListItem
                      key={"follow-up-item_list_" + item.id + idx}
                      id={item.id}
                      clock={item.clock_state}
                      label={item.label}
                      name={item.fieldName}
                      timeTillDueDate={item.relative_due_date}
                      dueDate={item.due_date}
                      detail={item.action_detail}
                      status={item.status}
                      actionType={item.actionType}
                      completedAt={item.completedAt}
                      completedBy={item.completedBy}
                      updatedAt={item.updatedAt}
                      updatedBy={item.updatedBy}
                      provider={item.provider}
                      client={item.client}
                    />
                  </ListItem>
                ))
              ) : (
                <NoAvailableFollowUps />
              )
            ) : (
              <LoadingIndicator isLoading={true} />
            )}
          </List>
        </div>
        {!state.fetchIsLoading && (
          <div className="py-2">
            <Stack alignItems={"center"}>
              <PaginationControls
                alignment={"center"}
                resultCount={state.resultCount}
                handleChange={handleChange}
                currentPage={state.currentPage}
              />
            </Stack>
          </div>
        )}
      </FollowUpListStateContext.Provider>
    </FollowUpListDispatchContext.Provider>
  );
}
