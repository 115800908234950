import { CalendarMonth, ClearOutlined } from "@mui/icons-material";
import { IconButton, MenuItem, Select } from "@mui/material";
import { DesktopDateTimePicker } from "@mui/x-date-pickers";
import React, { forwardRef, useId, useImperativeHandle, useRef } from "react";
import { inputStyles, selectStyles } from "../../constant";
import PropTypes from "prop-types";

const APPT_STATUSES = [
  { value: "Assigned", label: "Scheduled" },
  { value: "Overdue", label: "Missed" },
  { value: "Done", label: "Complete" }
];

function AppointmentForm({ onDelete, canDeleteRow }, ref) {
  const scheduledRef = useRef();
  const statusRef = useRef();
  const id = useId();

  useImperativeHandle(ref, () => ({
    getValues: () => ({
      scheduled: scheduledRef.current.value,
      status: statusRef.current.value
    })
  }));

  return (
    <div className="flex gap-2 items-center">
      <DesktopDateTimePicker
        closeOnSelect
        inputRef={scheduledRef}
        className="w-full"
        sx={{
          ...inputStyles,
          "& input": {
            marginLeft: "-8px"
          },
          "& .MuiOutlinedInput-root": {
            ...inputStyles["& .MuiOutlinedInput-root"],
            "& fieldset": {
              borderColor: "#9ca3af"
            }
          }
        }}
        slots={{
          OpenPickerIcon: CalendarMonth
        }}
        slotProps={{
          textField: { variant: "outlined" },
          inputAdornment: {
            position: "start"
          }
        }}
      />
      <Select
        id={`${id}_status`}
        className="w-full"
        inputRef={statusRef}
        defaultValue=""
        displayEmpty
        renderValue={value =>
          value
            ? APPT_STATUSES.find(s => s.value === value).label
            : <span className="text-gray-400">Appointment Status</span>}
        sx={{
          ...selectStyles,
          "& fieldset": {
            borderColor: "#9ca3af"
          }
        }}
      >
        {APPT_STATUSES.map(status =>
          <MenuItem key={status.value} value={status.value}>
            {status.label}
          </MenuItem>
        )}
      </Select>
      {canDeleteRow &&
        <IconButton color="error" className="h-fit m-auto" onClick={onDelete}>
          <ClearOutlined />
        </IconButton>}
    </div>
  );
}

export default forwardRef(AppointmentForm);

AppointmentForm.propTypes = {
  onDelete: PropTypes.func,
  canDeleteRow: PropTypes.bool
};
