import React, { useContext, useState, useEffect } from "react";
import axios from "../../../../../api/axios";
import DataContext from "../../../../../../context/DataContext";
import ProviderApptsv2 from "./ProviderApptsv2";
import { Dialog, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router-dom";
import { useDebouncedEffect } from "../../../../../hooks/useDebounceEffect";
import ProviderList from "./ProviderList";
import ModalShell from "../../../../../global/ModalShell";

function ClientProvidersv2({
  isAppointments,
  printable,
  caseInfo,
  activeProvObj,
}) {
  const { trigger, setTrigger, accessToken, activeBills, setActiveBills, userRoles } =
    useContext(DataContext);
  const [providerDetail] = useState();
  const [selectedAppt, setSelectedAppt] = useState();
  const [providerApptsList, setproviderApptsList] = useState([]);

  const [providersOpen, setProvidersOpen] = useState(false);
  const [apptsOpen, setApptsOpen] = useState(false);
  const [providerInfo, setProviderInfo] = useState([]);
  const [apptResLimit, setApptResLimit] = useState(20);
  const [showMoreApptsVisible, setShowMoreApptsVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [apptSearch, setApptSearch] = useState("");

  const [provResLimit, setProvResLimit] = useState(5);
  const [showMoreProvVisible, setShowMoreProvVisible] = useState(false);
  const [proCliTrigger, setProCliTrigger] = useState(false);
  const [orderingVal, setOrderingVal] = useState("-created_at");
  const [providerAmountObj, setProviderAmountObj] = useState({});

  const params = useParams();

  const apptsNeeded = {
    initial: 0,
    booked_or_overdue: 0,
    done: 0,
    done_percentage: "0.0%",
  };
  const setAllTriggers = () =>{
    setProCliTrigger(!trigger);
    setTrigger(!trigger);
  }

  useEffect(() => {
    if (
      userRoles.permissions.includes("provider.view_providersettlement") &&
      params
    ) {
      axios
        .get(`/api/provider_settlement/?case=${params.id}`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          const nestedData = response.data.results;
          let vals = {};
          for (const item in nestedData) {
            vals[nestedData[item].provider] = {
              initial_amount: nestedData[item].initial_amount,
              settled_amount: nestedData[item].settled_amount,
            };
          }
          setProviderAmountObj(vals);
        })
        .catch((response) => {
          console.log(response);
        });
    }
  }, [params, userRoles.permissions, accessToken]);

  useEffect(() => {
    if (
      userRoles.permissions.includes("provider.view_providerclientstatus") &&
      params
    ) {
      setLoading(true);
      setProviderInfo([]);
      axios
        .get(
          `/api/pro_cli_status/?limit=${provResLimit}&case=${params.id}&ordering=${orderingVal}`,
          {
            headers: { Authorization: `Token ${accessToken}` },
          }
        )
        .then((response) => {
          let data = response.data.results;
          if (response.data.count <= provResLimit) {
            setShowMoreProvVisible(false);
          } else {
            setShowMoreProvVisible(true);
          }
          setProviderInfo(data);
          setLoading(false);
        });
    } else {
      return;
    }
    // eslint-disable-next-line
  }, [
    accessToken,
    params,
    proCliTrigger,
    provResLimit,
    caseInfo,
    orderingVal,
    userRoles.permissions,
  ]);

  useDebouncedEffect(
    () => {
      if (
        userRoles.permissions.includes("provider.view_appointment") &&
        caseInfo
      ) {
        setLoading(true);
        let searchQuery = !apptSearch ? "" : `&search=${apptSearch}`;

        axios
          .get(
            `/api/appointments/?limit=${apptResLimit}&client=${caseInfo.client.pk}&ordering=-scheduled${searchQuery}`,
            {
              headers: { Authorization: `Token ${accessToken}` },
            }
          )
          .then((response) => {
            if (response.data.count <= apptResLimit) {
              setShowMoreApptsVisible(false);
            } else {
              setShowMoreApptsVisible(true);
            }
            setproviderApptsList(response.data.results);
            setLoading(false);
          });
      } else {
        return;
      }
    },
    [
      caseInfo,
      accessToken,
      apptResLimit,
      apptSearch,
      proCliTrigger,
      userRoles.permissions,
    ],
    250
  );

  useEffect(() => {
    if (
      userRoles.permissions.includes("provider.view_administeredservices") &&
      caseInfo
    ) {
      axios
        .get(
          `/api/administered/?limit=100&administered_to=${caseInfo.client.pk}`,
          {
            headers: { Authorization: `Token ${accessToken}` },
          }
        )
        .then((res) => {
          setActiveBills(res.data.results);
        });
    }
  }, [accessToken, trigger, setActiveBills, userRoles.permissions, caseInfo]);

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return (
    <>
      {isAppointments === false ? (
        <div className="sm:h-[60vh] overflow-y-hidden relative">
          <>
            <ProviderList
              proCliResponse={providerInfo}
              providerAmountObj={providerAmountObj}
              formatter={formatter}
              userRoles={userRoles}
              activeProvObj={activeProvObj}
              proCliTrigger={proCliTrigger}
              setProCliTrigger={setAllTriggers}
              setProvidersOpen={setProvidersOpen}
              providersOpen={providersOpen}
              setShowMoreProvVisible={setShowMoreProvVisible}
              showMoreProvVisible={showMoreProvVisible}
              setProvResLimit={setProvResLimit}
              provResLimit={provResLimit}
              orderingVal={orderingVal}
              setOrderingVal={setOrderingVal}
              loading={loading}
              caseInfo={caseInfo}
            />
          </>
        </div>
      ) : (
        <div className="sm:h-[60vh] overflow-y-hidden relative">
          <>
            <ProviderApptsv2
              apptsOpen={apptsOpen}
              setApptsOpen={setApptsOpen}
              apptsNeeded={apptsNeeded}
              providerDetail={providerDetail}
              providerApptsList={providerApptsList}
              setSelectedAppt={setSelectedAppt}
              selectedAppt={selectedAppt}
              setproviderApptsList={setproviderApptsList}
              printable={printable}
              apptResLimit={apptResLimit}
              setApptResLimit={setApptResLimit}
              showMoreApptsVisible={showMoreApptsVisible}
              setShowMoreApptsVisible={setShowMoreApptsVisible}
              activeProvObj={activeProvObj}
              activeBills={activeBills}
              loading={loading}
              apptSearch={apptSearch}
              setApptSearch={setApptSearch}
              caseInfo={caseInfo}
              proCliTrigger={proCliTrigger}
              setProCliTrigger={setAllTriggers}
            />
          </>
        </div>
      )}
      <ModalShell
        open={providersOpen}
        setOpen={setProvidersOpen}
        customWidth={"lg"}
      >
        <ProviderList
          proCliResponse={providerInfo}
          providerAmountObj={providerAmountObj}
          formatter={formatter}
          userRoles={userRoles}
          activeProvObj={activeProvObj}
          proCliTrigger={proCliTrigger}
          setProCliTrigger={setAllTriggers}
          setProvidersOpen={setProvidersOpen}
          providersOpen={providersOpen}
          caseInfo={caseInfo}
        />
      </ModalShell>
      <Dialog
        open={apptsOpen}
        maxWidth={"lg"}
        sx={{ margin: 10, borderRadius: 10 }}
      >
        <div style={{ padding: "2rem" }}>
          <Button onClick={() => setApptsOpen(false)}>
            <CloseIcon />
          </Button>
          <ProviderApptsv2
            apptsOpen={apptsOpen}
            apptsNeeded={apptsNeeded}
            providerDetail={providerDetail}
            providerApptsList={providerApptsList}
            setSelectedAppt={setSelectedAppt}
            selectedAppt={selectedAppt}
            setproviderApptsList={setproviderApptsList}
            isModalVersion={apptsOpen ? true : false}
            activeProvObj={activeProvObj}
            activebills={activeBills}
            setApptSearch={setApptSearch}
            apptSearch={apptSearch}
            caseInfo={caseInfo}
          />
        </div>
      </Dialog>
    </>
  );
}

export default ClientProvidersv2;
