import { Check, Close, Edit } from "@mui/icons-material";
import { Box, Chip, IconButton } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import axios from "../../../api/axios";
import DataContext from "../../../../context/DataContext";
import ApiRequestErrorHandler from "../../ApiRequestErrorHandler";
import ResponseModal from "../../ResponseModal";
import PropTypes from 'prop-types';

const ProviderTypeMultiple = ({ providerId, options, pickedOptions, setPickedOptions, trigger, setTrigger }) => {
	const [toggleEdit, setToggleEdit] = useState(false);
	const [responseModal, setResponseModal] = useState(false);
	const [responseBreakdown, setResponseBreakdown] = useState("");
	const [isError, setIsError] = useState(false);
	const { accessToken, userRoles } = useContext(DataContext);
	const [initialPickedOptions, setInitialPickedOptions] = useState(pickedOptions);
	const [isDisabled, setIsDisabled] = useState(false);
	const handleChipClick = (value) => {
		if (pickedOptions.length >= 5 && !pickedOptions.includes(value.key)) {
			return;
		}
		setPickedOptions((prevItems) => {
			const updatedItems = prevItems.includes(value.key)
				? prevItems.filter((item) => item !== value.key)
				: [...prevItems, value.key];
			return updatedItems.length > 0 ? updatedItems : prevItems;
		});
	};

	const compareArrays = (a, b) => {
		return a.length === b.length && a.every(item => b.includes(item));
	}

	useEffect(() => {
		setIsDisabled(compareArrays(pickedOptions, initialPickedOptions));
	}, [pickedOptions, initialPickedOptions])

	const submitSelectedOptions = () => {
		const patchData = {
			type: pickedOptions
		}
		axios
			.patch(`/api/provider/${providerId}/`, patchData, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Token ${accessToken}`
				},
			})
			.then(() => {
				setResponseModal(true);
				setResponseBreakdown(`Provider Types Updated`);
				setIsError(false);
				setInitialPickedOptions(pickedOptions);
			})
			.catch((error) => {
				const res = error.response.data;
				const errArr = ApiRequestErrorHandler(res);
				setIsError(true);
				setResponseModal(true);
				setResponseBreakdown(errArr);
			});
	}

	const handleCancel = () => {
		setToggleEdit(false);
		setPickedOptions(initialPickedOptions);
	};

	const closeResModal = () => {
		setIsError(false);
		setResponseModal(false);
		setResponseBreakdown("");
		if (isError) {
			setPickedOptions(initialPickedOptions);
		} else {
			setTrigger(!trigger);
			setToggleEdit(false);
		}
	};

	const getChipStyles = (opt, isEditMode) => {
		const isPicked = pickedOptions.includes(opt.key || opt);
		let backgroundColor;

		if (isEditMode) {
			backgroundColor = isPicked ? '#e7d8f9' : '';
		} else {
			backgroundColor = '#e7d8f9';
		}

		return {
			fontSize: "14px",
			fontWeight: "bold",
			borderRadius: "20px",
			padding: "4px 8px",
			color: "#6f72a8",
			borderColor: "#b1b4e0",
			backgroundColor: backgroundColor,
			pointerEvents: isEditMode ? 'auto' : 'none',
			cursor: isEditMode ? '' : 'default',
			'&:hover': {
				backgroundColor: '#e7d8f9 !important',
				borderColor: '#b1b4e0',
				boxShadow: 'none',
			},
			'&:active': {
				backgroundColor: '#b1b4e0 !important',
				borderColor: '#b1b4e0',
				boxShadow: 'none',
			}
		};
	};

	const handleChipDisabled = (opt) => {
		return pickedOptions.length >= 5 && !pickedOptions.includes(opt.key || opt);
	};

	return (
		<>
			<h3 className="my-2 flex items-center gap-2">
				<strong>Provider Type:</strong>
				{
					toggleEdit ? (
						<Box display="flex">
							<IconButton disabled={isDisabled} className="text-green-400" onClick={() => submitSelectedOptions()} aria-label="confirm edit">
								<Check />
							</IconButton>
							<IconButton onClick={() => handleCancel()} aria-label="cancel edit">
								<Close />
							</IconButton>
						</Box>
					) : (userRoles.permissions.includes("provider.change_provider") &&
						<IconButton onClick={() => setToggleEdit(true)} aria-label="edit">
							<Edit className="text-gray-400" />
						</IconButton>
					)
				}
			</h3>
			<h3 className="my-2 flex items-center gap-2">
				<Box display="flex" gap={2} marginLeft={2} flexWrap={"wrap"}>
					{(toggleEdit ? options : pickedOptions).map((opt, index) => (
						<Chip
							key={`${opt.key || opt}-${index}`}
							label={opt.key || opt}
							variant="outlined"
							sx={getChipStyles(opt, toggleEdit)}
							disabled={handleChipDisabled(opt)}
							onClick={() => toggleEdit && handleChipClick(opt)}
						/>
					))}
				</Box>
			</h3>
			<ResponseModal
				title={isError ? "Failed" : "Successful"}
				isError={isError}
				description={isError ? "" : responseBreakdown}
				openBool={responseModal}
				setOpenBool={setResponseModal}
				errorMessage={responseBreakdown}
				handleCloseFunc={closeResModal}
			/>
		</>
	)
}

export default ProviderTypeMultiple;

ProviderTypeMultiple.propTypes = {
	providerId: PropTypes.number.isRequired,
	options: PropTypes.array.isRequired,
	pickedOptions: PropTypes.array.isRequired,
	setPickedOptions: PropTypes.func.isRequired,
	trigger: PropTypes.bool.isRequired,
	setTrigger: PropTypes.func.isRequired
};